var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.ifSub}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"巡检项目名称","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ type: 'string', required: true, message: '项目名称不能为空' }], trigger: 'change' } ]),expression:"[\n          'name',\n          { rules: [{ type: 'string', required: true, message: '项目名称不能为空' }], trigger: 'change' },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"排序号","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sorter',
          {
            rules: [{ type: 'number', required: true, message: '排序号必须为数字' }],
            trigger: 'change',
            initialValue: 0,
          } ]),expression:"[\n          'sorter',\n          {\n            rules: [{ type: 'number', required: true, message: '排序号必须为数字' }],\n            trigger: 'change',\n            initialValue: 0,\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"step":0.1,"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"项目类型","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type', { rules: [{ required: true, message: '请选择项目类型' }], trigger: 'change' }]),expression:"['type', { rules: [{ required: true, message: '请选择项目类型' }], trigger: 'change' }]"}],attrs:{"allowClear":"","placeholder":"请选择"},on:{"change":_vm.handleQuestionTypeChange}},_vm._l((_vm.questionTypeSelect),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),(_vm.formItem.type === 'STATE')?_c('div',[_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('a-form-item',{key:k,attrs:{"label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol,"label":'第' + (index + 1) + '个可选项',"required":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            ("probablyOptionList[" + k + "]"),
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: '请输入可选项',
                } ],
            } ]),expression:"[\n            `probablyOptionList[${k}]`,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: true,\n                  whitespace: true,\n                  message: '请输入可选项',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"93%","margin-right":"4px"},attrs:{"placeholder":"请输入"},on:{"change":_vm.handleProbablyOptionChange}}),_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.removeProbablyOption(k); }}})],1)}),_c('a-row',{staticStyle:{"margin-bottom":"24px"}},[_c('a-col',{attrs:{"offset":5,"span":19}},[_c('a-button',{staticStyle:{"width":"88%"},attrs:{"type":"dashed"},on:{"click":_vm.addProbablyOption}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加可选项 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"默认值","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['defaultValue', { rules: [{ required: true, message: '请选择默认值' }] }]),expression:"['defaultValue', { rules: [{ required: true, message: '请选择默认值' }] }]"}],attrs:{"allowClear":"","placeholder":"请选择"},on:{"dropdownVisibleChange":_vm.handleQuestionDefaultValueSelectDropDown}},_vm._l((_vm.defaultValueList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],2):_vm._e(),(_vm.formItem.type === 'NUMBER')?_c('div',[_c('a-form-item',{attrs:{"label":"最大值","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'maxValue',
            {
              rules: [{ type: 'number', required: true, message: '最大值必须为数字' }],
              initialValue: 0,
            } ]),expression:"[\n            'maxValue',\n            {\n              rules: [{ type: 'number', required: true, message: '最大值必须为数字' }],\n              initialValue: 0,\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"step":0.1,"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"最小值","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'minValue',
            {
              rules: [{ type: 'number', required: true, message: '最小值必须为数字' }],
              initialValue: 0,
            } ]),expression:"[\n            'minValue',\n            {\n              rules: [{ type: 'number', required: true, message: '最小值必须为数字' }],\n              initialValue: 0,\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"step":0.1,"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"默认值","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'numberDefaultValue',
            { rules: [{ type: 'number', required: true, message: '默认值必须为数字' }], initialValue: 0 } ]),expression:"[\n            'numberDefaultValue',\n            { rules: [{ type: 'number', required: true, message: '默认值必须为数字' }], initialValue: 0 },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"step":0.1,"placeholder":"请输入"}})],1)],1):_vm._e(),(_vm.formItem.type === 'TEXT')?_c('div',[_c('a-form-item',{attrs:{"label":"默认值","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'textDefaultValue',
            { rules: [{ type: 'string', required: true, message: '默认值不能为空' }] } ]),expression:"[\n            'textDefaultValue',\n            { rules: [{ type: 'string', required: true, message: '默认值不能为空' }] },\n          ]"}],attrs:{"placeholder":"请输入"}})],1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"操作描述","label-col":_vm.questionLabelCol,"wrapper-col":_vm.questionWrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['actionDescribe']),expression:"['actionDescribe']"}],attrs:{"placeholder":"请输入","rows":4}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }